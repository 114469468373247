<template>
  <section>
    <a-row>
      <edit-category v-model="form"></edit-category>
    </a-row>
    <a-row>
      <edit-sub-category
        v-model="form"
        @deleteSub="deleteHandler"
      ></edit-sub-category>
      <edit-add-sub-category
        @edit-add-sub="editSubCategory"
        :parentId="form.id"
      ></edit-add-sub-category>
    </a-row>
    <a-row type="flex" class="mb-5 ml-3">
      <a-col :xs="{ span: 8 }" :md="{ span: 1 }" :lg="{ span: 2 }">
        <a-button
          @click="confirm(form.id)"
          size="large"
          class="bg-danger text-white mt-5 "
        >
          O'chirish
        </a-button>
      </a-col>
      <a-col :span="4">
        <a-button
          size="large"
          class="bg-warning text-white mt-5 mr-5"
          @click="editForm"
        >
          O'zgartirish
        </a-button>
      </a-col>
    </a-row>
    <modal v-if="inValid" @close="closeModal" title="Error! Bo'm-bosh inputlar">
      <p v-if="newSubCategory.length == 0" class="text-white bg-danger p-3 ">
        Agar yangi sub kategoriya qo'shmasangiz oxirgi bo'sh sub kategoriyani
        o'chirish tugmasini bosing iltimos!
      </p>
      <p class="text-white bg-danger p-3 " v-else>
        Iltimos bosh o'rinlarni hammasini to'ldirng
      </p>
    </modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditCategory from '../../components/EditCategoryCom/EditCategory.vue';
import EditSubCategory from '../../components/EditCategoryCom/EditSubCategory.vue';
import EditAddSubCategory from '../../components/EditCategoryCom/EditAddSubCategory.vue';
export default {
  //props ID routerdan keluvchi hisoblanadi
  props: ['id'],
  components: {
    EditCategory,
    EditSubCategory,
    EditAddSubCategory,
  },
  data() {
    return {
      newSubCategory: [],
      form: {},
      inValid: false,
    };
  },

  computed: {
    ...mapGetters({
      getData: 'category/getSingleCategory',
    }),
  },

  methods: {
    ...mapActions('category', [
      'getOneCategory',
      'patchOneCategory',
      'deleteOneCategory',
    ]),
    editSubCategory(data) {
      this.newSubCategory = data;
    },

    confirm(id) {
      this.$confirm({
        title: "O'chirish",
        content: "O'chirishga ishonchingiz komilmi",
        okText: 'Ha',
        cancelText: "Yo'q",
        onOk: () => {
          this.deleteOneCategory(id)
            .then(() => {
              this.$notification['success']({
                message: "Kategoriya o'chirildi ",
                description: "Kategoriya va sub kategoriya o'chirildi :)",
              });
              this.$router.push({ name: 'Categoriya' });
            })
            .catch(() => {
              this.$notification['error']({
                message: 'Xatolik yuz berdi',
                description: "Iltimos birozdan keyin urinib ko'ring",
              });
            });
        },
        onCancel() {
          return;
        },
      });
    },

    deleteHandler(id) {
      this.deleteOneCategory(id)
        .then(() => {
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
          this.fetchData();
        });
    },

    validate(data) {
      let err;
      outer: for (let i of data) {
        for (let j in i) {
          if (i[j] === '') {
            err = 'error';
            break outer;
          }
        }
      }
      return err ? true : false;
    },
    editForm() {
      const { name, name_en, name_ru, name_uz } = this.form;
      if (!name || !name_en || !name_ru || !name_uz) {
        this.inValid = true;
        return;
      }
      if (this.newSubCategory !== 'noData' && this.newSubCategory.length == 0) {
        this.inValid = true;
        return;
      }
      if (this.validate(this.form.children)) {
        this.inValid = true;
        return;
      }
      if (
        this.newSubCategory !== 'noData' &&
        this.validate(this.newSubCategory)
      ) {
        this.inValid = true;
        return;
      }

      const obj = {
        id: this.form.id,
        name: this.form.name,
        name_en: this.form.name_en,
        name_ru: this.form.name_ru,
        name_uz: this.form.name_uz,
        main: this.form.main,
        order: this.form.order,
        children_for_update: this.form.children,
        children_for_create:
          this.newSubCategory === 'noData' ? [] : this.newSubCategory,
      };
      // console.log(obj);
      const serverObj = {
        data: obj,
        id: this.id,
      };

      this.patchOneCategory(serverObj)
        .then(() => {
          this.$notification['success']({
            message: "Muffaqiyatli o'zgardi ",
            description: "Kategoriya va sub kategoriya o'zgardi :)",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Categoriya' });
          }, 1000);
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            duration: 13,
            description:
              "Sub kategorya bilan asosiy kategoriya bir xil bo'lganligi sabab sub kategoriya qo'shilmadi.Iltimos sub kategoryani asosiy kategoriya bilan bir xil emasligiga ishonchingiz komil bo'lgandan so'ng o'zgartirish tugamsini bosing",
          });
        });
    },
    closeModal(bool) {
      this.inValid = bool;
    },

    fetchData() {
      try {
        this.getOneCategory(this.id).then((res) => {
          this.form = res || {};
        });
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
