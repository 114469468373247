<template>
  <section class="container">
    <p v-if="inValid" :class="{ 'text-danger': inValid }">
      Iltimos bosh orinlarni barchasini to'diring
    </p>
    <a-row
      v-for="(child, index) in newChildren"
      :key="index"
      type="flex"
      justify="space-between"
    >
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi</label>
        <a-input v-model="child.name" @change="changeHandler" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [en]</label>
        <a-input v-model="child.name_en" @change="changeHandler" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [ru]</label>
        <a-input v-model="child.name_ru" @change="changeHandler" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [uz]</label>
        <a-input v-model="child.name_uz" @change="changeHandler" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 2 }">
        <label for="" class="mb-0 mt-2">Order</label>
        <a-input v-model="child.order" @change="changeHandler" type="number" />
      </a-col>
      <a-col :span="4">
        <label for="" class="mb-0 mt-2">O'chirish</label>
        <div>
          <a-button class="bg-danger text-white" @click="deleteSub(index)"
            >O'chirish</a-button
          >
        </div>
      </a-col>
    </a-row>

    <a-button type="primary" @click="addSub" style="margin-top:5px;">
      Qo'shish
    </a-button>
  </section>
</template>

<script>
export default {
  props: {
    parentId: Number,
  },
  data() {
    return {
      index: 0,
      newChildren: [
        {
          parent: Number(this.$route.params.id),
          name: '',
          name_en: '',
          name_ru: '',
          name_uz: '',
          order: '',
        },
      ],
      inValid: false,
    };
  },

  methods: {
    addSub() {
      const obj = {
        parent: this.parentId,
        name: '',
        name_en: '',
        name_ru: '',
        name_uz: '',
        order: '',
      };
      if (this.newChildren.length == 0) {
        this.index++;
        this.newChildren.push(obj);
      } else {
        const { name, name_en, name_ru, name_uz } = this.newChildren[
          this.index
        ];

        if (!name || !name_en || !name_ru || !name_uz) {
          this.inValid = true;
          return;
        }
        this.newChildren.push(obj);
        this.inValid = false;
        this.index++;

        this.$emit('edit-add-sub', this.newChildren);
      }
    },
    changeHandler() {
      this.$emit('edit-add-sub', this.newChildren);
    },

    deleteSub(index) {
      this.newChildren.splice(index, 1);
      this.index--;
      this.$emit('edit-add-sub', 'noData');
    },
  },
};
</script>
