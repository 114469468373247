<template>
  <section class="container mt-3">
    <a-row>
      <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
        <h4 class="font-weight-normal ">Asosiy kategoriya</h4>
        <form>
          <div>
            <label for="">Nomi </label>
            <a-input v-model="value.name" />
          </div>
          <div>
            <label for="">Nomi [eng] </label>
            <a-input v-model="value.name_en" />
          </div>
          <div>
            <label for="">Nomi [ru] </label>
            <a-input v-model="value.name_ru" />
          </div>
          <div>
            <label for="">Nomi [uz] </label>
            <a-input v-model="value.name_uz" />
          </div>
          <div>
            <label for="">Order</label>
            <a-input v-model="value.order" type="number" />
          </div>
          <div>
            <a-checkbox v-model="value.main" :checked="value.main" class="mt-2">
              Main
            </a-checkbox>
          </div>
        </form>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>
