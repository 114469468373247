<template>
  <section class="container">
    <h4 class="mt-4 font-weight-normal">Sub kategoriya</h4>
    <a-row
      v-for="child in value.children"
      :key="child.id"
      type="flex"
      justify="space-between"
    >
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi</label>
        <a-input v-model="child.name" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [en]</label>
        <a-input v-model="child.name_en" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [ru]</label>
        <a-input v-model="child.name_ru" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 4 }">
        <label for="" class="mb-0 mt-2">Nomi [uz]</label>
        <a-input v-model="child.name_uz" />
      </a-col>
      <a-col :xs="{ span: 24 }" :md="{ span: 2 }">
        <label for="" class="mb-0 mt-2">Order</label>
        <a-input v-model="child.order" type="number" />
      </a-col>
      <a-col :span="4">
        <label for="" class="mb-0 mt-2">O'chirish</label>
        <div>
          <a-button class="bg-danger text-white" @click="deleteSub(child.id)"
            >O'chirish</a-button
          >
        </div>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    deleteSub(id) {
      const sure = confirm('Sub categoriyani ochirishga ishonchingiz komilmi');
      if (sure) {
        this.$emit('deleteSub', id);
      } else {
        return;
      }
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>
